import React, { FC } from "react"
import { IPageTemplateProps } from "./Page"
import styled from "styled-components"
import { breakpoint } from "styles/breakpoints"
import { spacing } from "styles/spacing"
import { formatDate } from "utils"
import { Container } from "components/Shared/Container"
import { PageTitleWithDateSmall } from "components/Shared/PageTitle"
import SEO from "components/Seo"
import Breadcrumbs from "components/Breadcumbs"

const WrapperVideo = styled.div`
  height: calc(100vh - ${spacing.xl * 3.5}px);
  width: 100%;
  @media ${breakpoint.lg} {
    height: calc(100vh - ${spacing.xl * 4}px);
  }
  & iframe {
    height: 100%;
    width: 100%;
  }
`

const FilmTemplate: FC<IPageTemplateProps> = ({ pageContext, ...props }) => {
  return (
    <>
      <SEO
        title={pageContext.seo ? pageContext.seo.title : pageContext.title}
        description={pageContext.seo ? pageContext.seo.metaDesc : null}
        image={
          pageContext.seo &&
          pageContext.seo.opengraphImage &&
          pageContext.seo.opengraphImage.sourceUrl
            ? pageContext.seo.opengraphImage.sourceUrl
            : null
        }
      />
      <WrapperVideo dangerouslySetInnerHTML={{ __html: pageContext.iframe }} />
      <Container>
        <PageTitleWithDateSmall>
          {pageContext.title}
          <span>{formatDate(pageContext.date)}</span>
        </PageTitleWithDateSmall>
        <Breadcrumbs
          breadcrumbs={pageContext.seo ? pageContext.seo.breadcrumbs : null}
        />
      </Container>
    </>
  )
}

export default FilmTemplate
